import _ from 'lodash';

const DEFAULT_DEVICE_COUNT_DISPLAY_THRESHOLD = 100;
const DEFAULT_DEVICE_CHANGES_COUNT_DISPLAY_THRESHOLD = 100;

export const deviceManagement = {
  namespaced: true,
  state: {
    complimentaryDeviceCount: null,
    totalDeviceSlots: null,
    totalDeviceChanges: null,
    totalFreeDeviceSlots: 0,
    availableDeviceChanges: null,
    additionalDeviceChanges: null,
    deviceChangesResetTime: '',
    devices: [],
    wpaPskPassphrase: null,
    ssidName: null,
    ssidAuthType: null,
    deviceNameMaxChars: 50,
    deviceCountDisplayThreshold: null,
    deviceChangesCountDisplayThreshold: null,
    dpskMigrationNeeded: false,
  },
  getters: {},
  mutations: {
    upsertWPA(state, data) {
      state.wpaPskPassphrase = data;
    },
    upsertSSID(state, data) {
      state.ssidName = data.ssidName;
      state.ssidAuthType = data.ssidAuthType;
    },
    upsertDevices(state, updatedState) {
      _.assignIn(state, updatedState);
    },
    addDevice(state, device) {
      state.devices.push(device);
    },
    removeDevice(state, index) {
      state.devices.splice(index, 1);
    },
    removeConnection(state, { index, connectionIndex }) {
      state.devices[index].interfaces.splice(connectionIndex, 1);
    },
    setDeviceCountDisplayThreshold(state, data) {
      state.deviceCountDisplayThreshold = +data;
    },
    setDeviceChangesCountDisplayThreshold(state, data) {
      state.deviceChangesCountDisplayThreshold = +data;
    },
  },
  actions: {
    getDeviceCountDisplayThreshold({ commit }) {
      const deviceCountDisplayThreshold = this.state.global.publicConfig.DEVICE_COUNT_DISPLAY_THRESHOLD ? this.state.global.publicConfig.DEVICE_COUNT_DISPLAY_THRESHOLD : DEFAULT_DEVICE_COUNT_DISPLAY_THRESHOLD;
      commit('setDeviceCountDisplayThreshold', deviceCountDisplayThreshold);
    },

    getDeviceChangesCountDisplayThreshold({ commit }) {
      const deviceChangesCountDisplayThreshold = this.state.global.publicConfig.DEVICE_CHANGES_COUNT_DISPLAY_THRESHOLD ? this.state.global.publicConfig.DEVICE_CHANGES_COUNT_DISPLAY_THRESHOLD : DEFAULT_DEVICE_CHANGES_COUNT_DISPLAY_THRESHOLD;
      commit('setDeviceChangesCountDisplayThreshold', deviceChangesCountDisplayThreshold);
    },

    refreshWPAKey({ commit, rootState }) {
      return rootState.api.stardust.post('/network/handle-dpsk')
        .then((response) => {
          // Probably this should refresh the context after making the call
          // Issue: How to handle error case - i.e. WPA key didn't update, show error?
          commit('upsertWPA', response.data.passphrase);

          return response.data;
        });
    },
    fetchDevices({ commit, rootState }) {
      return rootState.api.stardust.get('/devices')
        .then((response) => {
          commit('upsertDevices', response.data);

          if (response.data && response.data.dpskMigrationNeeded) {
            dispatch('migrateDPSK');
          }

          return response.data;
        });
    },
    migrateDPSK({ rootState }) {
      return rootState.api.stardust.put('/network/cloudpath-dpsk');
    },
    addDevice({ commit, rootState }, data) {
      return rootState.api.stardust.post('/devices', data)
        .then((response) => {
          commit('addDevice', response.data);

          return response.data;
        });
    },
    removeDevice({ commit, rootState }, { index, ids }) {
      return rootState.api.stardust.put('/devices/delete', ids)
        .then((response) => {
          commit('removeDevice', index);

          return response.data;
        });
    },
    editDevice({ rootState }, { id, newName }) {
      return rootState.api.stardust.patch('/devices', {
        id,
        newName,
      })
        .then(response => response.data);
    },
  },
};
