import { Plan, PlansWOSingle } from '@/types/plan';
import { NewUser, User } from '@/types/user';

// NOTE: Meta, Reddit and TikTok Pixel tracking use same event naming,
// we will track all Reddit and TikTok events here
export enum MetaTrackingEvent {
  AddPaymentInfo = 'AddPaymentInfo',
  AddToCart = 'AddToCart',
  CompleteRegistration = 'CompleteRegistration', // SignUp - Reddit
  InitiateCheckout = 'InitiateCheckout',
  Lead = 'Lead',
  ViewContent = 'ViewContent',
  Purchase = 'Purchase', // CompletePayment - TikTok
}

export type MetaTrackingObject = {
  value: number;
  currency: string;
  contents: { id: string, quantity: number }[];
  'content_type': string;
};

export type RedditTrackingObject = {
  'external_id'?: string;
  value: number;
  currency: string;
  itemCount: number;
  products?: {
    id: number | string;
    name: string;
    category: string;
  }[];
  consent?: boolean; // GDPR - Privacy consent
};

export type TikTokTrackingObject = {
  value: number;
  currency: string;
  contents: {
    'content_id': string,
    quantity: number,
    price: number,
  }[];
  'content_type': string,
};

export function metaTrackCompleteRegistration(user: NewUser, plan?: Plan | PlansWOSingle) {
  // META
  if (window.fbq && user) {
    window.fbq('track', MetaTrackingEvent.CompleteRegistration, {
      content_name: 'Registration Form',
      content_category: 'Signup',
      content_ids: [user.userId],
      content_type: 'user_registration',
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
    });
  }

  // Reddit
  if (window.rdt && user) {
    window.rdt('track', 'SignUp', {
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      method: 'Email',
      external_id: user.userId,
    });
  }

  // TikTok
  if (window.ttq && user && plan) {
    window.ttq.track(MetaTrackingEvent.CompleteRegistration, {
      value: plan.price,
      currency: plan.currency || 'GBP',
      contents: [{
        content_id: plan.priceId,
        quantity: 1,
        price: plan.price,
      }],
      content_type: 'product',
    });
  }
}

export function metaTrackEvent(sessionId: string, user: User, plan: Plan | PlansWOSingle, chargedAmount: number, eventName: MetaTrackingEvent) {
  // META
  if (window.fbq && sessionId && user && plan && chargedAmount && eventName) {
    let trackingObj: MetaTrackingObject | null = null;
    trackingObj = {
      value: chargedAmount,
      currency: plan.currency || 'GBP',
      contents: [{
        id: plan.priceId,
        quantity: 1,
      }],
      content_type: 'product',
    };

    if (trackingObj) window.fbq('track', eventName, trackingObj);
  }

  // Reddit
  if (window.rdt && sessionId && user && plan && chargedAmount && eventName) {
    let trackingObj: RedditTrackingObject | null = null;

    trackingObj = {
      external_id: user.id,
      value: chargedAmount,
      currency: plan.currency || 'GBP',
      itemCount: 1,
      products: [{
        id: plan.priceId,
        name: plan.name || plan.title,
        category: 'plan',
      }],
    };

    if (trackingObj && [MetaTrackingEvent.ViewContent, MetaTrackingEvent.AddToCart, MetaTrackingEvent.Purchase].includes(eventName)) {
      window.rdt('track', eventName, trackingObj);
    }
  }

  // TikTok
  if (window.ttq && sessionId && user && plan && chargedAmount && eventName) {
    let trackingObj: TikTokTrackingObject | null = null;
    const trackEventName = eventName !== MetaTrackingEvent.Purchase ? eventName : 'CompletePayment';

    trackingObj = {
      value: chargedAmount,
      currency: plan.currency || 'GBP',
      contents: [{
        content_id: plan.priceId,
        quantity: 1,
        price: plan.price,
      }],
      content_type: 'product',
    };

    if (trackingObj) window.ttq.track(trackEventName, trackingObj);
  }
}
